
import {Prop} from 'vue-property-decorator';
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class NavigationToggle extends Vue {
  @Prop({default: null}) private ariaExpanded?: string | null;

  private handleClick(e: Event) {
    e.preventDefault();
    const menu = document.getElementById('nav_mobile')!;
    const activeClassList: string = 'navigation__mobile--active';
    const isOpen = menu.classList.contains(activeClassList);

    if(isOpen) {
      menu.classList.remove(activeClassList)
    } else {
      menu.classList.add(activeClassList)
    }

  }

}
