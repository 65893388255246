
import {Prop} from 'vue-property-decorator';
import {Options, Vue} from 'vue-class-component';

@Options({})
export default class ScrollButton extends Vue {
  @Prop({default: null}) private ariaExpanded?: string | null;

  private handleClick(): void {
    const sections: HTMLCollection = document.getElementsByTagName('section');
    const options: object = {
      behavior: "smooth"
    }
    sections[2].scrollIntoView(options)
  }

}
