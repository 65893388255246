/* eslint-disable */



require("./scss/main.scss");
// @ts-ignore
document.addEventListener("touchstart", function() {}, false);

declare let __webpack_public_path__: string;
__webpack_public_path__ = _mgnl.contextPath + "/" + __webpack_public_path__;
/* eslint-enable */

import {createApp, defineAsyncComponent} from "vue";
import NavigationToggle from '@/components/NavigationToggle.vue';
import {Swiper, SwiperSlide} from "swiper/vue";
import ScrollButton from "@/components/ScrollButton.vue";

const app = createApp({
   components: {
       // use this to enable components that need to be exported
       // DemoComponent: defineAsyncComponent(() => import('@/components/DemoComponent.vue'))
      VideoComponent: defineAsyncComponent(() => import('@/components/VideoComponent.vue')),
      ContactFormComponent: defineAsyncComponent(() => import('@/components/ContactForm.vue')),
      BlogOverviewComponent: defineAsyncComponent(() => import('@/components/BlogOverview.vue')),
      Slider: defineAsyncComponent(() => import('@/components/Slider.vue')),
      CookieDeclaration: defineAsyncComponent(() => import('@/components/CookieDeclaration.vue')),
      NavigationToggle,
      Swiper: Swiper,
      SwiperSlide: SwiperSlide,
      ScrollButton: ScrollButton,

   }
});

app.config.performance = true;
app.config.compilerOptions.comments = true;

app.mount('#app');